<template>
  <div>
    <div v-if="showLogin">
      <router-view></router-view>
    </div>
    <div v-else-if="showInviteGuide">
      <router-view></router-view>
    </div>
    <div
      v-else
      id="app"
      v-loading.fullscreen.lock="globalStatus.globalLoad"
      :element-loading-text="elementLoadingText"
    >
      <div v-if="showApp">
        <tabs
          v-if="showHeader"
          :data="computedPages"
          :active="active"
          @translate="translateAside"
          @globalConfig="showSetting"
          @softFeedback="showSoftFeedback"
        ></tabs>

        <div class="app-content">
          <container
            :routes="routesPath"
            :active="currentPath"
            :show-child="false"
          ></container>

          <transition name="aside">
            <mu-aside
              v-show="isAsideShow"
              :routes="routes"
              :active="currentPath"
              class="aside-wrapper"
            >
              <div class="logo-wrapper">
                <div class="image-wrapper text-align-center">
                  <!-- <img src="@/assets/images/logo.png" width="90px" height="90px" /> -->
                </div>
              </div>
            </mu-aside>
          </transition>

          <transition name="mark">
            <mu-mark
              v-show="isAsideShow"
              class="mark-wrapper"
              @click.native="hideAside"
            ></mu-mark>
          </transition>
        </div>
      </div>
      <setting-dialog ref="settingDialog" @finish="finishInit"></setting-dialog>
      <welcome-page
        :dialog-visible="welcomePageVisible"
        @finish="finishInit"
        @showToVideoGuide="showToVideoGuide"
      ></welcome-page>
      <to-video-guide-dialog
        :dialog-visible="toVideoGuideVisible"
      ></to-video-guide-dialog>
      <soft-feedback-dialog ref="softFeedbackDialog"></soft-feedback-dialog>
      <!-- gpt对话框 -->
      <!-- <div><gpt-chat></gpt-chat></div> -->

      <el-backtop
        target="#app"
        :bottom="30"
        :right="40"
        :visibility-height="10"
      >
        <i class="el-icon-caret-top"></i>
      </el-backtop>
    </div>
    <vue-fab
      ref="helpFab"
      size="big"
      icon="question_mark"
      main-btn-color="#409EFF"
      :global-options="{ spacing: 50 }"
      style="position: fixed; right: 5vw; bottom: 20%"
    >
      <fab-item
        :idx="0"
        title="咨询客服"
        icon="support_agent"
        @clickItem="clickToShowQrCode"
      />
      <!-- <fab-item
        :idx="1"
        title="视频教程"
        icon="videocam"
        @clickItem="clickToShowVideoCourse"
      /> -->
    </vue-fab>
    <video-player-dialog
      :dialog-visible="showVideoPlayer"
      :video-path.sync="videoPath"
    ></video-player-dialog>
  </div>
</template>

<script>
// import gptChat from "@/components/gpt-chat";
import { ability } from "@/ability/template";
import axios from "@/common/js/ajax";
import { debounce } from "@/common/js/utils";
import MuMark from "@/components/base/mu-mark/mu-mark";
import Tabs from "@/components/base/tabs/tabs.vue";
import Container from "@/components/container/container.vue";
import { initAbility } from "@/pages/index/router/config";
import { PAGES } from "@/pages/pages-config";
import { ROUTES } from "@/pages/router-config";
import Api from "api";
import SettingDialog from "components/container/setting-dialog";
import SoftFeedbackDialog from "components/container/soft-feedback-dialog";
import MuAside from "@/components/mu-aside/mu-aside";
import { mapGetters, mapMutations } from "vuex";

// import logan from "common/utils/logan";
// import {ROUTER_TYPES as KEYWORD_TYPES} from "pages/keyword/router/router-types";
import ws from "@/common/js/ws";
// import moment from "moment";
import { AI_SUPERMAN_VIDEO_ARTIFACT_VERSION } from "@/common/const/video-course";
import {
  checkBookmarks,
  getCurrentPluginVersion,
} from "@/common/js/chromeExtension";
import { SERVICE_QRCODE } from "@/common/js/const";
import ToVideoGuideDialog from "@/components/container/dialog/to-video-guide-dialog";
import WelcomePage from "@/components/container/welcome-page.vue";
import _ from "lodash";
import videoPlayerDialog from "./product/views/video-bank/dialog/videoPlayerDialog.vue";

// const BASIC_TAB_NAMES = ["关键词", "产品"];
// const FIXED_TIME = "2022-2-17 12:00:00";
// const FORMAT_TIME = "YYYY-MM-DD HH:mm:ss";

export default {
  components: {
    // gptChat,
    videoPlayerDialog,
    ToVideoGuideDialog,
    WelcomePage,
    Tabs,
    Container,
    MuAside,
    MuMark,
    SettingDialog,
    SoftFeedbackDialog,
  },
  data() {
    return {
      active: 0,
      routesPath: [],
      isAsideShow: false,
      showApp: false,
      showBrowserTip: false,
      disabledBrowserText: "",
      showNoAuthority: false,
      welcomePageVisible: false,
      toVideoGuideVisible: false,
      showVideoPlayer: false,
      videoPath: "",
    };
  },
  computed: {
    ...mapGetters(["showHeader"]),
    currentPath() {
      return this.$route.path;
    },
    pathname() {
      return location.pathname;
    },
    showLogin() {
      return this.currentPath === "/login" && this.pathname === "/login.html";
    },
    showInviteGuide() {
      return (
        this.currentPath === "/invite-guide" && this.pathname === "/tools.html"
      );
    },
    elementLoadingText() {
      if (this.showBrowserTip) {
        return (
          this.disabledBrowserText || "不支持当前浏览器，请使用 Chrome 浏览器。"
        );
      }
      if (this.showNoAuthority) {
        return "当前账号已过期，请联系运营或销售人员续费开通。";
      } else {
        return "加载中, 请稍等。";
      }
    },
    computedPages() {
      return this.getTabNames(PAGES);
    },
    routes() {
      return this.getTabNames(ROUTES);
    },
    ...mapGetters(["account", "globalStatus"]),
  },

  created() {
    const paths = ["/login", "/invite-guide"];
    const state = paths.some((s) => location.hash.includes(s));
    if (!state) {
      this.getUserStatus();
    }

    // 监听同行分析
    const that = this;
    window.addEventListener("message", function (e) {
      if (e.data) {
        console.log("message listener", e.data);
        const { module, type, value } = e.data;
        if (module === "superman-report" && type == "documentTitle") {
          document.title = value;
        }
        if (module === "superman-report" && type == "industry-hot-inquiries") {
          const shop = value?.match(/[//]*([\w-]+)\./)?.[1] + ".en.alibaba.com";
          console.log("🚀 ~ shop:", shop);
          const rou = that.$router.resolve({
            path: "industry-hot-inquiries",
            query: {
              shop,
            },
          });
          // console.log(rou);
          window.open(rou.href, "_blank");
        }
      }
    });
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementsByClassName("fab-cantainer")[0].click();
    });
  },

  methods: {
    clickToShowQrCode() {
      this.$confirm(
        `<p style="color: #409eff">有任何问题或需求请联系我们</p>
              <p>工作日8:30～12:00，13:30～18:00</p>
              <img
                style="width: 12rem; "
                src="${SERVICE_QRCODE}"
                alt="暂无图片"
              />`,
        {
          dangerouslyUseHTMLString: true,
          center: true,
          showCancelButton: false,
          showConfirmButton: false,
        }
      );
    },
    clickToShowVideoCourse() {
      this.showVideoPlayer = true;
      this.videoPath = AI_SUPERMAN_VIDEO_ARTIFACT_VERSION;
    },
    sendMessage(text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen
          ? this.newMessagesCount
          : this.newMessagesCount + 1;
        this.onMessageWasSent({
          author: "user1",
          type: "text",
          data: { text },
        });
      }
    },
    onMessageWasSent(message) {
      // called when the user sends a message
      this.messageList = [...this.messageList, message];
      let newResponse = {
        author: "user1",
        data: { text: "加载中...." },
        type: "text",
        ignore: true,
      };
      this.messageList = [...this.messageList, newResponse];
      // 发送请求
      let url = "api/v1/gpt";
      let messages = this.messageList;
      let data = messages.map((msg) => {
        if (msg.ignore) {
          return;
        }
        let record = {
          role: msg.author === "user1" ? "assistant" : "user",
          content: msg.data.text,
        };
        return record;
      });
      data = data
        .filter((msg) => msg != null)
        .slice(data.length - 9, data.length);
      axios({
        url: url,
        method: "post",
        data: data,
      })
        .then((res) => {
          let data = res.choices[0].message.content;
          let message = {
            author: "user1",
            data: { text: data },
            type: "text",
          };
          this.messageList.pop();
          this.messageList = [...this.messageList, message];
        })
        .catch(() => {
          let message = {
            author: "user1",
            data: { text: "请求超时，请重试" },
            type: "text",
            ignore: true,
          };
          this.messageList.pop();
          this.messageList = [...this.messageList, message];
        });
    },
    openChat() {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true;
      this.newMessagesCount = 0;
    },
    closeChat() {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false;
    },
    handleScrollToTop() {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType() {},
    editMessage(message) {
      const m = this.messageList.find((m) => m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    },
    getUserStatus() {
      const url = Api.getUserStatus;
      this.setGlobalLoad(true);
      if (navigator.userAgent.indexOf("Chrome") === -1) {
        this.showBrowserTip = true;
        return;
      }
      // Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.102  Safari/537.36 NetType/WIFI MicroMessenger/6.8.0(0x16080000) MacWechat/3.8(0x13080010) XWEB/30515 Flue
      if (navigator.userAgent.indexOf("Wechat") !== -1) {
        this.showBrowserTip = true;
        this.disabledBrowserText =
          "不支持微信内置浏览器，请使用 Chrome 浏览器。";
        return;
      }
      // Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.5359.125 CEF/108.109.11 CEF/6.00 Qianniu/10.80.05E AlibabaSupplier/10.80.05E Safari/537.36
      if (navigator.userAgent.indexOf("AlibabaSupplier") !== -1) {
        this.showBrowserTip = true;
        this.disabledBrowserText = "不支持阿里卖家，请使用 Chrome 浏览器。";
        return;
      }
      axios({
        method: "get",
        url: url,
      })
        .then((res) => {
          this.setAccount(res);
          this.init();
        })
        .catch((err) => {
          let {
            data: { subCode },
            status,
          } = err.response;
          if (status === 403 && subCode === 13) {
            this.showNoAuthority = true;
          }
        })
        .finally(() => {});
    },
    init() {
      console.log(`浏览器版本: ${navigator.userAgent}`);
      Api.getSoftwareApp(this.account.belongsCorp).then((res) => {
        if (res.status >= 200 && res.status < 300) {
          res.json().then((data) => {
            const { softwareInfo } = data.data;
            // console.log(softwareInfo);
            const map = _.keyBy(softwareInfo, "softwareCode");
            // console.log(map);
            this.setMarketingApp(map);
          });
        }
      });
      checkBookmarks()
        .then((res) => {
          this.$store.commit("setBookmarkAdded", res);
        })
        .catch(() => {});
      getCurrentPluginVersion().then((res) => {
        console.log(
          `操盘手插件版本：${res}, 插件id: ${localStorage.getItem(
            "ai-plugin-id"
          )}`
        );
      });
      // const { init } = this.account;
      this.setGlobalLoad(false);
      this.showApp = true;
      initAbility(this, ability);
      this.globalWs();
      this.initRouterPath();
      // if (!init) {
      //   this.welcomePageVisible = true;
      //   return;
      // }
    },
    showToVideoGuide() {
      this.toVideoGuideVisible = true;
    },
    finishInit() {
      this.getUserStatus();
      const pathName = "/industry-hot-products";
      if (this.$route.path === pathName) {
        console.log("刷新全局配置");
        location.reload(true);
      }
    },
    pollingAccount() {
      const polling = debounce(this.getUserStatus, 2000 * 10, false);
      polling();
    },
    globalWs() {
      const id = this.account.id;
      const subscribeUrl = Api.userReplyMessage.replace("{id}", id);

      ws({
        cacheApi: null,
        url: null,
        subscribeUrl,
      })
        .then(() => {})
        .catch(() => {});
    },
    initRouterPath() {
      let pathname = location.pathname;
      console.log(pathname);
      this.computedPages.forEach((o, index) => {
        if (o.href.startsWith(pathname)) {
          this.active = index;
          this.routesPath = [this.routes[index]];
        }
      });
    },
    ...mapMutations({
      setAccount: "SET_ACCOUNT",
      setGlobalLoad: "SET_GLOBAL_LOAD",
      setMarketingApp: "SET_MARKETING_APP",
    }),
    translateAside() {
      this.isAsideShow = true;
    },
    hideAside() {
      this.isAsideShow = false;
    },
    showSetting() {
      this.$refs.settingDialog.show();
    },
    showSoftFeedback() {
      this.$refs.softFeedbackDialog.show();
    },
    getTabNames(type) {
      // const {accountType, createdDate} = this.account;
      // const dateNode = moment(FIXED_TIME).format(FORMAT_TIME);
      // const timestamp = moment(dateNode).valueOf();
      // if(accountType === "BASIC"){
      //   if (createdDate > timestamp) {
      //     return type.filter(item => BASIC_TAB_NAMES.includes(item.name));
      //   }
      //   return type.filter(item => item.name !== "优化指南");
      // }
      return type;
    },
  },
};
</script>

<style lang="stylus" scoped>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-text;
  height: 100vh;
  overflow-x hidden

  .app-content {
    padding: 0 .75rem;
    margin-top: 4.25rem;
    height: 100%;
  }
                                    .aside-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    z-index: 10;
    background-color: white;

    &.aside-enter-active, &.aside-leave-active {
      transition: all 0.4s;
    }

    &.aside-enter, &.aside-leave-to {
      transform: translate(-100%, 0);
    }

    .logo-wrapper {
      position: relative;
      height: 8rem;

      .icon-wrapper {
        position: absolute;
        right: 2rem;
        bottom: 0;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .mark-wrapper {
    &.mark-enter-active, &.mark-leave-active {
      transition: all 0.4s;
    }

    &.mark-enter, &.mark-leave-to {
      opacity: 0;
    }
  }
  .el-dialog {
    border-radius 1rem
  }
}
.el-icon-setting {
  color: $color;
}
</style>

<style lang="stylus">
.el-notification.custom-notify.left {
  width: 4.3rem;
}

@media screen and (max-width: $md-screen) {
  .el-notification.custom-notify.left {
    display: none;
  }
}

div.el-loading-spinner p.el-loading-text {
  font-size: 18px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

body .el-table colgroup.gutter {
  display: table-cell !important;
}
</style>
