import axios from "axios";
// import { ERR } from "@/common/js/config";
import notify from "@/common/js/notify";
import { encode } from "@/common/js/utils";
import { SASS_LOGIN_URL } from "./config";
// import Logan from "common/utils/logan";

const LOGIN_HAS_EXPIRED = 401;
const NO_AUTHORITY = 403;

const handleError = function (err) {
  let {
    data: { message, subCode },
    status,
  } = err.response;

  const match = message?.match(/"message":"([^"]+)"/); // gtp请求超时处理
  if (match) {
    message = match[1];
    console.log("gtp请求超时"); // "程序异常请稍候重试"
    return;
  }

  if (status === LOGIN_HAS_EXPIRED) {
    if (process.env.NODE_ENV === "production") {
      location.replace(SASS_LOGIN_URL);
    }
    return;
  } else if (!message) {
    message = "系统错误";
  }
  if (status === NO_AUTHORITY && subCode === 13) {
    return;
  }
  notify.warn({
    message,
  });
};

const Axios = function (options) {
  return new Promise((resolve, reject) => {
    options = Object.assign(
      {
        paramsSerializer(params) {
          return encode(params);
        },
        headers: { "X-Requested-With": "XMLHttpRequest" },
        timeout: 1000 * 60 * 100,
      },
      options
    );
    axios(options)
      .then((res) => {
        if (res && res.data) {
          resolve(res.data);
        } else {
          resolve();
        }
      })
      .catch((err) => {
        handleError(err);
        reject(err);
        console.log({ options, err });
        throw new Error(err);
      });
  });
};

export default Axios;
